<template>
  <DatasetManager>
    <v-row no-gutters>
      <v-col cols="auto" class="mx-2">
        <a :href="descriptionModelData" target="_blank">Beschreibung der Modelldaten</a>
      </v-col>
      <v-col cols="auto">
        <a :href="descriptionResultData" target="_blank">Beschreibung der Ergebnisdaten</a>
      </v-col>
    </v-row>
  </DatasetManager>
</template>

<script>
import DatasetManager from '@/components/DatasetManager.vue';

export default {
  components: {
    DatasetManager
  },
  computed: {
    descriptionModelData() {
      return `./docs/erlaeuterung_int_modell.pdf`;
    },
    descriptionResultData() {
      return `./docs/erlaeuterung_int_ergeb.pdf`;
    }
  }
};
</script>
